/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
   */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 100%;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
img {
  max-width: 100%;
  height: auto;
}
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  /* 1 */
  overflow: visible;
  /* 1 */
  height: 0;
  box-sizing: content-box;
  /* 2 */
}
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  /* 1 */
  margin: 0;
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 2 */
}
/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input[type=submit] {
  border: none;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
button,
input {
  /* 1 */
  overflow: visible;
}
/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}
/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type=checkbox],
[type=radio] {
  /* 1 */
  padding: 0;
  box-sizing: border-box;
  /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}
/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}
/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}
[hidden] {
  display: none;
}
body {
  font-family: "Acumin Pro", sans-serif;
  font-size: 14px;
  color: #2b2b2b;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "GT Super Ds", sans-serif;
  font-weight: 500;
}
.h1,
.module h1 {
  font-size: 100px;
  line-height: 1.1;
}
@media (max-width: 767px) {
  .h1,
  .module h1 {
    font-size: 30px;
    line-height: 1.2;
  }
}
.h2,
.module h2 {
  font-size: 80px;
  line-height: 1.12;
}
@media (max-width: 767px) {
  .h2,
  .module h2 {
    font-size: 30px;
    line-height: 1.2;
  }
}
.h3,
.module h3 {
  font-size: 40px;
  line-height: 1.25;
}
@media (max-width: 767px) {
  .h3,
  .module h3 {
    font-size: 20px;
    line-height: 1.3;
  }
}
.h4,
.module h4 {
  font-size: 30px;
  line-height: 1.33;
}
@media (max-width: 767px) {
  .h4,
  .module h4 {
    font-size: 20px;
    line-height: 1.3;
  }
}
.h5,
.module h5 {
  font-size: 20px;
  line-height: 1.25;
}
@media (max-width: 767px) {
  .h5,
  .module h5 {
    font-size: 16px;
  }
}
.h6,
.module h6 {
  font-size: 16px;
  line-height: 1.25;
}
@media (max-width: 767px) {
  .h6,
  .module h6 {
    font-size: 14px;
  }
}
a {
  text-decoration: none;
  color: #2b2b2b;
}
* {
  outline: none;
  box-sizing: border-box;
}
html,
body {
  background-color: #fff;
  scroll-behavior: smooth;
  scroll-padding-top: var(--header-height);
}
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
.container {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 105px;
}
@media (max-width: 1500px) {
  .container {
    padding: 0 70px;
  }
}
@media (max-width: 991px) {
  .container {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  .container {
    padding: 0 30px;
  }
}
@media (max-width: 575px) {
  .container {
    padding: 0 15px;
  }
}
.container--unless-width {
  max-width: unset !important;
}
.content {
  flex: 1 0 auto;
}
svg.icon {
  width: 20px;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  clip: rect(0 0 0 0);
}
picture img {
  filter: blur(0);
  transition-property: filter;
  transition-duration: 0.3s;
}
.lazy img {
  filter: blur(3px);
}
.page-overlay {
  position: relative;
  pointer-events: none;
}
.page-overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: block;
  visibility: visible;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
}
.page-overlay.page-overlay--white::before {
  background-color: #ffffff;
}
.page-overlay.page-overlay--full::before {
  z-index: 3;
}
.hidden {
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  padding: 0;
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
}
.hide {
  display: none;
}
.aspect-ratio {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.aspect-ratio::before {
  content: "";
  display: block;
}
.aspect-ratio > img {
  max-height: 100%;
  max-width: 100%;
}
.aspect-ratio--with-fallback > img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.aspect-ratio--with-fallback {
  padding-bottom: 0 !important;
  /* For older browsers we use the padding-bottom trick, so make sure to remove it here */
}
.aspect-ratio::before {
  padding-bottom: calc(100% / (var(--aspect-ratio)));
}
.aspect-ratio > img,
.aspect-ratio > noscript img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.width--100 {
  width: 100%;
  max-width: 100%;
}
.width--50 {
  width: 100%;
  max-width: 50%;
}
.flex {
  display: flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.template-customers .content {
  display: flex;
}
.snize-sticky-searchbox {
  display: none !important;
}
.desktop-only {
  display: block;
}
.mobile-only {
  display: none;
}
.loop-onstore {
  z-index: 999999 !important;
}
@media (max-width: 767px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
}
.account__reset-modal {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(43, 43, 43, 0.5019607843);
}
.account__reset-modal-content {
  max-width: 400px;
  width: 100%;
  padding: 30px;
  background-color: #fff;
  font-size: 18px;
  text-align: center;
}
@media (max-width: 767px) {
  .account__reset-modal-content {
    padding: 20px;
    font-size: 14px;
  }
}
.account__reset-modal-message {
  color: #198754;
}
.account__reset-modal-redirecting-icon {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-top: 15px;
  border: 3px solid rgba(43, 43, 43, 0.4);
  border-radius: 50%;
  vertical-align: middle;
}
.account__reset-modal-redirecting-icon::after {
  content: "";
  position: absolute;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
  border: 3px solid transparent;
  border-radius: 50%;
  border-top-color: #ffffff;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.image-abs-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-abs-contain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.template-404 .content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.empty-page {
  padding: 100px 0;
}
.empty-page .empty-page__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 620px;
}
.empty-page .empty-page__title {
  margin-bottom: 20px;
  font-size: 45px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #2b2b2b;
}
.empty-page .empty-page__subtitle {
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
}
.empty-page .empty-page__link {
  padding-top: 22px;
  padding-bottom: 21px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: #2b2b2b;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.22;
  letter-spacing: 0.3em;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  color: #ffffff;
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}
@media (-moz-touch-enabled: 0), (hover: hover) {
  .empty-page .empty-page__link:hover {
    background-color: #666666;
  }
}
@media (max-width: 1199px) {
  .empty-page .empty-page__title {
    font-size: 35px;
  }
  .empty-page .empty-page__subtitle {
    font-size: 20px;
  }
}
@media (max-width: 991px) {
  .empty-page .empty-page__title {
    margin-bottom: 18px;
    font-size: 33px;
  }
  .empty-page .empty-page__subtitle {
    margin-bottom: 18px;
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .empty-page .empty-page__title {
    font-size: 31px;
  }
  .empty-page .empty-page__subtitle {
    font-size: 17px;
  }
  .empty-page .empty-page__link {
    padding-top: 11px;
    padding-bottom: 11px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
    line-height: 1.333;
    letter-spacing: 0.25em;
  }
}
@media (max-width: 575px) {
  .empty-page .empty-page__title {
    margin-bottom: 15px;
    font-size: 29px;
  }
  .empty-page .empty-page__subtitle {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .empty-page .empty-page__title {
    font-size: 27px;
  }
  .empty-page .empty-page__subtitle {
    font-size: 15px;
  }
}
@media (max-width: 380px) {
  .empty-page .empty-page__title {
    font-size: 25px;
  }
  .empty-page .empty-page__subtitle {
    font-size: 14px;
  }
}